import { Button, FormGroup, InputGroup, Intent, Menu, MenuItem, TagInput } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";

const StyledFieldGoogleAdSearchStandard = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .keywords {
        grid-column-start: 1;
        grid-column-end: 3;
    }
    @media screen and (max-width: 800px) {
        display: block;
    }
    .bp4-form-group {
        width: 50%;
    }
`;

const StyledGoogleAdsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 15px;
`;

const HEADLINE_CHAR_LIMIT = 30;
const HEADLINE_COUNT_LIMIT = 15;
const LONG_HEADLINE_COUNT_LIMIT = 5;
const LONG_HEADLINE_CHAR_LIMIT = 90;
export const DESCRIPTION_CHAR_LIMIT = 90;
const DESCRIPTION_COUNT_LIMIT = 5;

const buildForGroupProps = ({ errors, name, dataValue, maxLength, placeHolder }) => {
    return {
        labelFor: `gad-${name}`,
        helperText: errors[name] || placeHolder || `${(dataValue || "").length}/${maxLength}`,
        intent: (dataValue || "").length > maxLength ? Intent.DANGER : Intent.NONE,
    };
};
const handleInputChange = (index, type, role, value, onChange, newValue) => {
    const filteredValues = (value || []).filter((elt) => elt.type === type && elt.role === role);
    const globalIndex = (value || []).findIndex(
        (elt, i) => elt.type === type && elt.role === role && filteredValues.indexOf(elt) === index
    );

    const updatedValues = (value || []).map((elt, i) => {
        if (i === globalIndex) {
            return { ...elt, value: newValue };
        }
        return elt;
    });

    onChange(updatedValues);
};
const FieldGoogleTexts = ({ value, onChange, disabled, loading, errors }) => {
    useEffect(() => {
        if (!value?.find((v) => v.type === "keyword"))
            onChange([...value, { value: "", type: "keyword", role: "keywords" }]);
    }, [onChange, value]);

    const dataValue = useMemo(() => value || {}, [value]);
    const handleCopyKeywords = useCallback(async () => {
        const permission = await navigator.permissions.query({ name: "clipboard-write" });
        if (permission.state === "denied") {
            throw new Error("Not allowed to write clipboard.");
        }

        await navigator.clipboard.writeText(dataValue.find((v) => v.type === "keyword").value);
    }, [dataValue]);
    return (
        <StyledGoogleAdsContainer>
            <StyledFieldGoogleAdSearchStandard>
                {(value || [])
                    .filter((elt) => elt.type === "headline")
                    .map((v, index) => {
                        return (
                            <FormGroup
                                label={`Headline ${index + 1}`}
                                {...buildForGroupProps({
                                    errors,
                                    name: "headline",
                                    dataValue: v.value,
                                    maxLength: HEADLINE_CHAR_LIMIT,
                                })}
                            >
                                <InputGroup
                                    id="gad-headline1"
                                    disabled={disabled || loading}
                                    value={v.value || ""}
                                    onChange={(e) =>
                                        handleInputChange(
                                            index,
                                            "headline",
                                            "headlines",
                                            value,
                                            onChange,
                                            e.target.value
                                        )
                                    }
                                    maxLength={HEADLINE_CHAR_LIMIT}
                                />
                            </FormGroup>
                        );
                    })}
                {(value || []).filter((elt) => elt.type === "headline").length < HEADLINE_COUNT_LIMIT && (
                    <Button
                        onClick={() => onChange([...value, { value: "", type: "headline", role: "headlines" }])}
                        text="Add Headline"
                        icon="plus"
                    />
                )}
            </StyledFieldGoogleAdSearchStandard>
            <StyledFieldGoogleAdSearchStandard>
                {(value || [])
                    .filter((elt) => elt.type === "long_headline")
                    .map((v, index) => {
                        return (
                            <FormGroup
                                label={`Long Headline ${index + 1}`}
                                {...buildForGroupProps({
                                    errors,
                                    name: "long_headline",
                                    dataValue: v.value,
                                    maxLength: LONG_HEADLINE_CHAR_LIMIT,
                                })}
                            >
                                <InputGroup
                                    id="gad-headline1"
                                    disabled={disabled || loading}
                                    value={v.value || ""}
                                    onChange={(e) =>
                                        handleInputChange(
                                            index,
                                            "long_headline",
                                            "long_headlines",
                                            value,
                                            onChange,
                                            e.target.value
                                        )
                                    }
                                    maxLength={LONG_HEADLINE_CHAR_LIMIT}
                                />
                            </FormGroup>
                        );
                    })}
                {(value || []).filter((elt) => elt.type === "long_headline").length < LONG_HEADLINE_COUNT_LIMIT && (
                    <Button
                        onClick={() =>
                            onChange([...value, { value: "", type: "long_headline", role: "long_headlines" }])
                        }
                        text="Add Long Headline"
                        icon="plus"
                    />
                )}
            </StyledFieldGoogleAdSearchStandard>
            <StyledFieldGoogleAdSearchStandard>
                {(value || [])
                    .filter((elt) => elt.type === "description")
                    .map((v, index) => {
                        return (
                            <FormGroup
                                label={`Description ${index + 1}`}
                                {...buildForGroupProps({
                                    errors,
                                    name: "description",
                                    dataValue: v.value,
                                    maxLength: DESCRIPTION_CHAR_LIMIT,
                                })}
                            >
                                <InputGroup
                                    id="gad-description1"
                                    disabled={disabled || loading}
                                    value={v.value || ""}
                                    onChange={(e) =>
                                        handleInputChange(
                                            index,
                                            "description",
                                            "descriptions",
                                            value,
                                            onChange,
                                            e.target.value
                                        )
                                    }
                                    maxLength={DESCRIPTION_CHAR_LIMIT}
                                    rows="4"
                                    fill
                                />
                            </FormGroup>
                        );
                    })}
                {(value || []).filter((elt) => elt.type === "description").length < DESCRIPTION_COUNT_LIMIT && (
                    <Button
                        onClick={() => onChange([...value, { value: "", type: "description", role: "descriptions" }])}
                        text="Add Description"
                        icon="plus"
                    />
                )}
            </StyledFieldGoogleAdSearchStandard>
            {(value || [])
                .filter((elt) => elt.type === "keyword")
                .map((v, index) => {
                    return (
                        <FormGroup className="keywords" label="Keywords">
                            <TagInput
                                id="gad-keywords"
                                disabled={disabled || loading}
                                values={v.value.split(",")}
                                onChange={(values) => {
                                    const newValue = values.filter((val) => val.trim() !== "").join(",");
                                    handleInputChange(index, "keyword", "keywords", value, onChange, newValue);
                                }}
                                placeholder="Separate values with 'enter'..."
                                tagProps={{
                                    minimal: true,
                                }}
                                addOnBlur
                                addOnPaste
                                rightElement={
                                    <Popover2
                                        interactionKind="click"
                                        placement="bottom"
                                        content={
                                            <Menu>
                                                <MenuItem
                                                    text="Copy all"
                                                    icon="duplicate"
                                                    onClick={handleCopyKeywords}
                                                />
                                                <MenuItem
                                                    text="Clear"
                                                    icon="cross"
                                                    disabled={disabled}
                                                    onClick={() =>
                                                        handleInputChange(
                                                            index,
                                                            "keyword",
                                                            "keywords",
                                                            value,
                                                            onChange,
                                                            ""
                                                        )
                                                    }
                                                />
                                            </Menu>
                                        }
                                        renderTarget={({ isOpen, ref, ...targetProps }) => (
                                            <Button {...targetProps} elementRef={ref} icon="chevron-down" />
                                        )}
                                    />
                                }
                            />
                        </FormGroup>
                    );
                })}
        </StyledGoogleAdsContainer>
    );
};

FieldGoogleTexts.propTypes = {
    value: PropTypes.arrayOf(PropTypes.shape({})),
    emptyValue: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    errors: PropTypes.shape({}),
};

FieldGoogleTexts.defaultProps = {
    value: [],
    emptyValue: null,
    onChange: null,
    disabled: false,
    loading: false,
    errors: {},
};

export default FieldGoogleTexts;
