export let ampHistoryToken = null;
export let ampAiWebsocket = null;

const AI_CALL_TIMEOUT = 20000;

const initServerSide = async (messageId, aiSessionId) => {
    try {
        const appUrl = window.ENVIRONMENT === "local" ? "http://localhost:11001" : window.APP_SERVER_URL;
        const url = `${appUrl}/_ai/staff`;
        const controller = new AbortController();
        const fetchTimeout = setTimeout(() => {
            // eslint-disable-next-line no-console
            console.log("Cannot join AI server");
            controller.abort();
            return false;
        }, AI_CALL_TIMEOUT);
        let response = await fetch(url, {
            signal: controller.signal,
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json; charset=UTF-8",
            },
            credentials: "include",
            body: JSON.stringify({ ai_session_id: aiSessionId, message_id: messageId }),
        });
        clearTimeout(fetchTimeout);
        if (response.ok) {
            response = await response.json();
            return response.ai_session_id;
        }
        return false;
    } catch (e) {
        return false;
    }
};

const manageCallResponse = (answerUrl, taskId, resolve, reject) => {
    fetch(`${answerUrl}/${taskId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        body: null,
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error, status: ${response.status}`);
            }
            return response.json();
        })
        .then(
            // eslint-disable-next-line no-loop-func,consistent-return
            (responseData) => {
                if (responseData.status === "done") {
                    const jsonResult = JSON.parse(responseData.result);
                    resolve(jsonResult);
                } else if (responseData.status === "error") {
                    reject();
                } else if (responseData.status === "working") {
                    setTimeout(() => manageCallResponse(answerUrl, taskId, resolve, reject), 1000);
                } else {
                    reject();
                }
            }
        )
        .catch((error) => {
            reject(error);
        });
};

export const callAi = (wizardData, lang, action, feedback = null) =>
    new Promise((resolve, reject) => {
        try {
            let descriptionAi = wizardData?.wizardMetas?.ai_business_description;
            if (wizardData?.wizardMetas?.ai_business_description) {
                const parts = wizardData.wizardMetas.ai_business_description.split("$$@@");
                descriptionAi = `Product Description: ${parts[0] || ""}\n
                                    Features and Benefits: ${parts[1] || ""}\n
                                     Target Audience: ${parts[2] || ""}\n
                                     Promotion Objectives: ${parts[3] || ""}`.trim();
            }
            const data = {
                adLink: wizardData.adLink,
                user_lang: lang,
                ai_business_description: descriptionAi,
                wizard_metas: JSON.stringify(wizardData.wizardMetas),
                ad_goal: wizardData?.adGoal,
                feedback,
            };

            initServerSide(wizardData.id, null).then((response) => {
                if (response === false) {
                    reject();
                    return;
                }
                fetch(`${window.WIZARD_AI_BASE_URL}/query`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        action,
                        data,
                        ai_session_id: response,
                    }),
                })
                    .then(
                        (queryResponse) => queryResponse.json() // Convert the response data to a JSON object
                    )
                    .then((queryResponseData) => {
                        const taskId = queryResponseData.task_id;
                        manageCallResponse(`${window.WIZARD_AI_BASE_URL}/answer`, taskId, resolve, reject);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        } catch (error) {
            reject();
        }
    });

export const callAiTool = (messageId, tool, data) =>
    new Promise((resolve, reject) => {
        try {
            initServerSide(messageId, null).then((response) => {
                if (response === false) {
                    reject();
                    return;
                }
                fetch(`${window.WIZARD_AI_BASE_URL}/query_tool`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        tool,
                        data,
                        ai_session_id: response,
                    }),
                })
                    .then(
                        (queryResponse) => queryResponse.json() // Convert the response data to a JSON object
                    )
                    .then((queryResponseData) => {
                        const taskId = queryResponseData.task_id;
                        manageCallResponse(`${window.WIZARD_AI_BASE_URL}/answer`, taskId, resolve, reject);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        } catch (error) {
            reject();
        }
    });

window.callAiTool = callAiTool;
